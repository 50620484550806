<template>
    <div align="center">
        <br>
        <div align="center">
            <span class="text-h4">Documenti allegati alla Quotazione</span>
        </div>
        <br><br>

        <div class="row justify-center">

            <div class="col-12 col-md-4" align="left">
                <q-list  separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Area:</q-item-label>
                            <q-item-label caption>{{quotazione.area}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Prodotto:</q-item-label>
                            <q-item-label caption>{{quotazione.id_prodotto}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Numero preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.guid_preventivo}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Descrizione preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.nominativo}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Stato della quotazione:</q-item-label>
                            <q-item-label caption>{{getStato}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Unit&agrave; Operativa:</q-item-label>
                            <q-item-label caption>{{quotazione.unita_operativa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Operatore:</q-item-label>
                            <q-item-label caption>{{quotazione.operatore}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>

            <div class="col-12 col-md-6 q-px-md" align="left">
                <h5 class="q-mt-sm q-mb-sm q-pt-sm">Elenco documenti</h5>

                <div v-if="elenco_documenti.length === 0">Nessun documento allegato</div>

                <div v-if="elenco_documenti.length > 0">

                    <q-list bordered  separator>
                        <q-item tag="label" v-ripple  v-for="(documento,index) in elenco_documenti" v-bind:key="index" @click.native="onScaricaDocumento(documento)">
                            <q-item-section>
                                <q-item-label>Nome del documento</q-item-label>
                                <q-item-label caption>{{documento.label}}</q-item-label>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label>Dimensione del file</q-item-label>
                                <q-item-label caption>{{documento.file_size}} bytes</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Info documento</q-item-label>
                                <q-item-label caption>{{documento.value[0].descrizione_documento}}</q-item-label>
                            </q-item-section>
                            <q-item-section avatar>
                                <q-avatar color="orange-3" text-color="black" icon="mdi-download" />
                            </q-item-section>
                        </q-item>
                    </q-list>

                </div>
            </div>
        </div>

        <br><br>
        <hr>
        <div class="row" align="center">
            <div class="col-12 col-md-12" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    :disable="!is_loading"
                    @click.native="onIndietroClicked"
                />
            </div>
        </div>

        <br><br><br><br>

    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    import { mapFields} from "vuex-map-fields";
    import { mapState, mapActions } from "vuex";
    import commonLib from "@/libs/commonLib.js";

    export default {
        name: "AllegaDocumenti",
        data() {
            return {
                quotazione: {},
                elenco_documenti: [],
                documenti_allegati: [],
                totale_file_allegati: 0,
                dati_preventivo: {},
                is_loading: true
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapFields('gestioneQuotazioni', [
                "idquotazione"
            ]),
            ...mapState({
                formPreventivo: state => state.formPreventivo
            }),
            getStato() {
                if (commonLib.isEmpty(this.quotazione)) return "";

                return this.quotazione.stato.replaceAll("_"," ");
            }
        },
        methods: {
            ...mapActions({
                fetchDatiQuotazione: "gestioneQuotazioni/fetchDatiQuotazione",
                fetchElencoDocumentiDaPreventivo: "gestioneDocumenti/fetchElencoDocumentiDaPreventivo",
                fetchDownloadUrl: "gestioneDocumenti/fetchDownloadUrl",
                fetchUploadUrl: "gestioneDocumenti/fetchUploadUrl",
                fetchDatiPreventivo: "formPreventivo/fetchDatiPreventivo"
            }),
            async onScaricaDocumento(documento) {
                var NumeroPreventivo = this.formPreventivo.preventivo.id;
                var anno = documento.annoCreazione;
                var mese = documento.meseCreazione+"";

                mese = mese.padStart(2,"0");

                var Filename = "documenti/preventivi/"+anno+"/"+mese+"/"+NumeroPreventivo+"/"+documento.label;

                // Richiese url per il download
                var url = await this.fetchDownloadUrl(Filename);

                window.location = url;
            },
            onIndietroClicked() {
                this.$router.push({name : "Quotazioni.MenuGestioneQuotazioni"});
            }
        },
        async mounted() {
            this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
            var elenco_documenti = await this.fetchElencoDocumentiDaPreventivo(this.quotazione.guid_preventivo);
            await this.fetchDatiPreventivo(this.quotazione.guid_preventivo);
            this.elenco_documenti = elenco_documenti.data.result;

            //console.log("elenco_documenti:",this.elenco_documenti);
            //console.log("formPreventivo:",this.formPreventivo);
        }

    }

</script>
